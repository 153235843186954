import Phaser from 'phaser';

export default class Bomb {
    constructor(scene, x, y) {
        this.scene = scene;
        this.sprite = scene.physics.add.sprite(100, 100, 'objects').play('bomb_off');
        this.sprite.body.setSize(30, 30);
        this.sprite.body.setOffset(72, 80);
        this.sprite.setBounce(0.8);
        this.sprite.setCollideWorldBounds(true);
        this.sprite.setDrag(200);
        this.state = 'idle';
        this.lastKickTime = 0;
        this.hideBomb();
        this.carrier = null; // New property to track which player is carrying the bomb
    }

    ignite() {
        if (this.state !== 'ticking') {
            this.state = 'ticking';
            this.sprite.play('bomb_on');
            this.scene.sound.play('bombFizz');
        }
    }

    explode() {
        if (this.state === 'exploding') {
            return;
        }

        this.sprite.setGravity(0, 0);
        this.sprite.setVelocity(0, 0);
        this.sprite.body.enable = false

        this.scene.sound.stopByKey('bombFizz');
        this.isTicking = false;
        this.isExploding = true;

        let players = [this.scene.player, ...Object.values(this.scene.otherPlayers)];

        players.forEach(player => {
            if (!player.isDead) {
                const distance = Phaser.Math.Distance.Between(this.sprite.x, this.sprite.y, player.sprite.x, player.sprite.y);
                if (distance <= 100) { // Adjust the radius as needed
                    player.takeDamage();
                }
            }
        });
        this.sprite.play('bomb_explosion');

        const onAnimationComplete = (animation, frame) => {
            if (animation.key === 'bomb_explosion') {
                // Ensure bomb is hidden and disabled after explosion
                this.sprite.setVisible(false);
                this.sprite.body.enable = false;
                this.sprite.off('animationcomplete', onAnimationComplete); // Remove listener after execution
                this.isExploding = false;
                this.scene.socketManager.emitBombExplodedComplete();
                // set animation back to idle
                this.sprite.play('bomb_off');
            }
        };
        this.sprite.on('animationcomplete', onAnimationComplete);
        this.scene.sound.play('bombExplosion');
        if (this.carrier) {
            this.carrier.carryingBomb = false;
            this.carrier = null
        }
    }

    updateBomb(bomb) {

        if (this.isHidden()) {
            return;
        }

        this.sprite.setPosition(bomb.x, bomb.y);
        this.sprite.setVelocity(bomb.velocityX, bomb.velocityY);
    }

    setPosition(x, y) {
        this.sprite.setPosition(x, y);
    }

    setVelocity(velocityX, velocityY) {
        this.sprite.setVelocity(velocityX, velocityY);
    }

    update() {
        if (this.carrier) {
            this.setPosition(this.carrier.sprite.x, this.carrier.sprite.y - 5); // Adjust position based on player's position
        }
    }

    hideBomb() {
        this.sprite.setVisible(false);
        this.sprite.body.enable = false;
    }

    showBomb() {
        this.sprite.setVisible(true);
        this.sprite.body.enable = true;
    }

    isHidden() {
        return !this.sprite.visible
    }

    // Method to pick up the bomb
    pickUp(player) {
        this.carrier = player;
        this.sprite.body.enable = false; // Disable physics while carried
    }

    // Method to drop the bomb
    dropBomb(x, y, kickStrength, direction, isPlayerJumping, socketManager) {
        this.sprite.lastKickTime = this.scene.time.now + 500;
        this.carrier = null;
        this.sprite.body.enable = true;
        this.setPosition(x, y);
        this.setVelocity(kickStrength * direction, - kickStrength);
        console.log('drop velocity', kickStrength, direction, isPlayerJumping);
        this.sprite.setDepth(0); // You can adjust this to your needs
        socketManager.emitKickBomb(this.sprite);
        this.scene.sound.play('kickBomb');
    }
}
