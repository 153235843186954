// src/scenes/Preloader.js
import Phaser from 'phaser';
import { loadAssets } from '@/utils/AssetLoader';
import { v4 as uuidv4 } from 'uuid';

export default class Preloader extends Phaser.Scene {
    constructor() {
        super('Preloader');
    }

    preload() {
        // Load all the game assets
        loadAssets(this);

        // Optional: Display loading progress
        const progressBar = this.add.graphics();
        const progressBox = this.add.graphics();
        progressBox.fillStyle(0x222222, 0.8);
        progressBox.fillRect(240, 270, 320, 50);

        const loadingText = this.make.text({
            x: this.cameras.main.width / 2,
            y: this.cameras.main.height / 2 - 50,
            text: 'Loading...',
            style: {
                font: '20px monospace',
                fill: '#ffffff'
            }
        }).setOrigin(0.5);

        this.load.on('progress', (value) => {
            progressBar.clear();
            progressBar.fillStyle(0xffffff, 1);
            progressBar.fillRect(250, 280, 300 * value, 30);
        });

        this.load.on('complete', () => {
            progressBar.destroy();
            progressBox.destroy();
            loadingText.destroy();
        });
    }

    create() {
        const map = this.make.tilemap({ key: 'preload' });
        const tileset = map.addTilesetImage('Tile-Sets (64-64)', 'tiles');
        this.backgroundLayer = map.createLayer('background', tileset, 0, 0);

        const mapWidth = map.widthInPixels;
        const mapHeight = map.heightInPixels;

        // Calculate the center of the map
        const mapCenterX = mapWidth / 2;
        const mapCenterY = mapHeight / 2;

        // Center the camera on the map center
        this.cameras.main.centerOn(mapCenterX, mapCenterY);
        const playButton = this.add.text(this.cameras.main.width / 2, this.cameras.main.height / 2, 'Play', {
            fontSize: '48px',
            fill: '#fff'
        }).setOrigin(0.5).setInteractive();
        this.cameras.main.setZoom(2.5);

        // Set up camera
        this.uiCamera = this.cameras.add(0, 0, this.cameras.main.width, this.cameras.main.height);
        this.uiCamera.ignore(this.children.list.filter(child =>
            child !== playButton
        ));
        this.cameras.main.ignore(playButton);

        // Check if UUID exists in cookies, otherwise generate and store it
        let deviceUUID = this.getCookie('deviceUUID');
        if (!deviceUUID) {
            deviceUUID = uuidv4();
            this.setCookie('deviceUUID', deviceUUID, 365);
        }

        // Check if the username is already in the database and store it
        fetch(process.env.API_URL + `/get-username.php`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ deviceUUID })
        })
            .then(response => response.json())
            .then(data => {
                if (data.success && data.username) {
                    this.registry.set('username', data.username);
                    this.registry.set('deviceUUID', deviceUUID);
                }
            })
            .catch(error => {
                console.error('Error fetching username:', error);
            });

        playButton.on('pointerup', () => {
            this.handlePlayButton(deviceUUID);
        });
    }

    handlePlayButton(deviceUUID) {
        let username = this.registry.get('username');
        if (!username) {
            username = prompt('Please enter your username:');
            if (username) {
                this.registerUser(username, deviceUUID);
            } else {
                return;
            }
        }
        this.scene.start('MainMenu');
    }

    registerUser(username, deviceUUID) {
        fetch(process.env.API_URL + `/register.php`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ username, deviceUUID })
        })
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    this.registry.set('username', username);
                    // Proceed to the game...
                } else {
                    // Handle registration error...
                    console.error(data.message);
                }
            })
            .catch(error => {
                console.error('Error:', error);
            });
    }

    setCookie(name, value, days) {
        const d = new Date();
        d.setTime(d.getTime() + (days * 24 * 60 * 60 * 1000));
        const expires = "expires=" + d.toUTCString();
        document.cookie = name + "=" + value + ";" + expires + ";path=/";
    }

    getCookie(name) {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) return parts.pop().split(';').shift();
    }
}
