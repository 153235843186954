// src/animations/Animations.js

export function createAnimations(scene) {
    // Player animations
    scene.anims.create({
        key: 'idle',
        frames: scene.anims.generateFrameNames('player', {
            start: 0,
            end: 25,
            prefix: '1-Player-Bomb Guy ',
            suffix: '.aseprite'
        }),
        frameRate: 20,
        repeat: -1
    });

    scene.anims.create({
        key: 'run',
        frames: scene.anims.generateFrameNames('player', {
            start: 26,
            end: 39,
            prefix: '1-Player-Bomb Guy ',
            suffix: '.aseprite'
        }),
        frameRate: 20,
        repeat: -1
    });

    scene.anims.create({
        key: 'jump',
        frames: scene.anims.generateFrameNames('player', {
            start: 41,
            end: 44,
            prefix: '1-Player-Bomb Guy ',
            suffix: '.aseprite'
        }),
        frameRate: 10,
        repeat: 0
    });

    scene.anims.create({
        key: 'jump-anticipation',
        frames: scene.anims.generateFrameNames('player', {
            start: 40,
            end: 40,
            prefix: '1-Player-Bomb Guy ',
            suffix: '.aseprite'
        }),
        frameRate: 10,
        repeat: 0
    });

    scene.anims.create({
        key: 'hit',
        frames: scene.anims.generateFrameNames('player', {
            start: 50,
            end: 57,
            prefix: '1-Player-Bomb Guy ',
            suffix: '.aseprite'
        }),
        frameRate: 10,
        repeat: 0
    });

    scene.anims.create({
        key: 'dead-hit',
        frames: scene.anims.generateFrameNames('player', {
            start: 58,
            end: 63,
            prefix: '1-Player-Bomb Guy ',
            suffix: '.aseprite'
        }),
        frameRate: 10,
        repeat: 0
    });

    scene.anims.create({
        key: 'dead-ground',
        frames: scene.anims.generateFrameNames('player', {
            start: 64,
            end: 67,
            prefix: '1-Player-Bomb Guy ',
            suffix: '.aseprite'
        }),
        frameRate: 10,
        repeat: 0
    });

    // Bomb animations
    scene.anims.create({
        key: 'bomb_off',
        frames: scene.anims.generateFrameNames('objects', {
            start: 44,
            end: 44,
            prefix: '7-Objects ',
            suffix: '.aseprite'
        }),
        frameRate: 10,
        repeat: 0
    });

    scene.anims.create({
        key: 'bomb_on',
        frames: scene.anims.generateFrameNames('objects', {
            start: 45,
            end: 54,
            prefix: '7-Objects ',
            suffix: '.aseprite'
        }),
        frameRate: 10,
        repeat: -1
    });

    scene.anims.create({
        key: 'bomb_explosion',
        frames: scene.anims.generateFrameNames('objects', {
            start: 55,
            end: 63,
            prefix: '7-Objects ',
            suffix: '.aseprite'
        }),
        frameRate: 8,
        repeat: 0
    });

    // Pirate
    scene.anims.create({
        key: 'bold-pirate_idle',
        frames: scene.anims.generateFrameNames('bold-pirate', {
            start: 0, end: 33, prefix: 'bold-pirate ', suffix: '.aseprite'
        }),
        frameRate: 20,
        repeat: -1
    });

    scene.anims.create({
        key: 'bold-pirate_run',
        frames: scene.anims.generateFrameNames('bold-pirate', {
            start: 34, end: 47, prefix: 'bold-pirate ', suffix: '.aseprite'
        }),
        frameRate: 20,
        repeat: -1
    });

    scene.anims.create({
        key: 'bold-pirate_jump',
        frames: scene.anims.generateFrameNames('bold-pirate', {
            start: 49, end: 52, prefix: 'bold-pirate ', suffix: '.aseprite'
        }),
        frameRate: 20,
        repeat: -1
    });

    scene.anims.create({
        key: 'bold-pirate_jump-anticipation',
        frames: scene.anims.generateFrameNames('bold-pirate', {
            start: 48,
            end: 48,
            prefix: 'bold-pirate ',
            suffix: '.aseprite'
        }),
        frameRate: 10,
        repeat: 0
    });

    scene.anims.create({
        key: 'bold-pirate_hit',
        frames: scene.anims.generateFrameNames('bold-pirate', {
            start: 70,
            end: 77,
            prefix: 'bold-pirate ',
            suffix: '.aseprite'
        }),
        frameRate: 10,
        repeat: 0
    });

    scene.anims.create({
        key: 'bold-pirate_dead-hit',
        frames: scene.anims.generateFrameNames('bold-pirate', {
            start: 78,
            end: 83,
            prefix: 'bold-pirate ',
            suffix: '.aseprite'
        }),
        frameRate: 10,
        repeat: 0
    });

    scene.anims.create({
        key: 'bold-pirate_dead-ground',
        frames: scene.anims.generateFrameNames('bold-pirate', {
            start: 84,
            end: 87,
            prefix: 'bold-pirate ',
            suffix: '.aseprite'
        }),
        frameRate: 10,
        repeat: 0
    });

    // Cucumber
    scene.anims.create({
        key: 'cucumber_idle',
        frames: scene.anims.generateFrameNames('cucumber', {
            start: 0, end: 35, prefix: 'cucumber-', suffix: '.aseprite'
        }),
        frameRate: 10,
        repeat: -1
    });

    scene.anims.create({
        key: 'cucumber_run',
        frames: scene.anims.generateFrameNames('cucumber', {
            start: 36, end: 47, prefix: 'cucumber-', suffix: '.aseprite'
        }),
        frameRate: 10,
        repeat: -1
    });

    scene.anims.create({
        key: 'cucumber_jump',
        frames: scene.anims.generateFrameNames('cucumber', {
            start: 49, end: 52, prefix: 'cucumber-', suffix: '.aseprite'
        }),
        frameRate: 10,
        repeat: -1
    });

    scene.anims.create({
        key: 'cucumber_jump-anticipation',
        frames: scene.anims.generateFrameNames('cucumber', {
            start: 48,
            end: 48,
            prefix: 'cucumber-',
            suffix: '.aseprite'
        }),
        frameRate: 10,
        repeat: 0
    });

    scene.anims.create({
        key: 'cucumber_hit',
        frames: scene.anims.generateFrameNames('cucumber', {
            start: 58,
            end: 64,
            prefix: 'cucumber-',
            suffix: '.aseprite'
        }),
        frameRate: 10,
        repeat: 0
    });

    scene.anims.create({
        key: 'cucumber_dead-hit',
        frames: scene.anims.generateFrameNames('cucumber', {
            start: 66,
            end: 71,
            prefix: 'cucumber-',
            suffix: '.aseprite'
        }),
        frameRate: 10,
        repeat: 0
    });

    scene.anims.create({
        key: 'cucumber_dead-ground',
        frames: scene.anims.generateFrameNames('cucumber', {
            start: 72,
            end: 75,
            prefix: 'cucumber-',
            suffix: '.aseprite'
        }),
        frameRate: 10,
        repeat: 0
    });

    // Big guy
    scene.anims.create({
        key: 'big-guy_idle',
        frames: scene.anims.generateFrameNames('big-guy', {
            start: 0, end: 37, prefix: 'big-guy-', suffix: '.aseprite'
        }),
        frameRate: 10,
        repeat: -1
    });

    scene.anims.create({
        key: 'big-guy_run',
        frames: scene.anims.generateFrameNames('big-guy', {
            start: 38, end: 53, prefix: 'big-guy-', suffix: '.aseprite'
        }),
        frameRate: 20,
        repeat: -1
    });

    scene.anims.create({
        key: 'big-guy_jump',
        frames: scene.anims.generateFrameNames('big-guy', {
            start: 102, end: 105, prefix: 'big-guy-', suffix: '.aseprite'
        }),
        frameRate: 10,
        repeat: -1
    });

    scene.anims.create({
        key: 'big-guy_jump-anticipation',
        frames: scene.anims.generateFrameNames('big-guy', {
            start: 101,
            end: 101,
            prefix: 'big-guy-',
            suffix: '.aseprite'
        }),
        frameRate: 10,
        repeat: 0
    });

    scene.anims.create({
        key: 'big-guy_hit',
        frames: scene.anims.generateFrameNames('big-guy', {
            start: 111,
            end: 118,
            prefix: 'big-guy-',
            suffix: '.aseprite'
        }),
        frameRate: 10,
        repeat: 0
    });

    scene.anims.create({
        key: 'big-guy_dead-hit',
        frames: scene.anims.generateFrameNames('big-guy', {
            start: 119,
            end: 124,
            prefix: 'big-guy-',
            suffix: '.aseprite'
        }),
        frameRate: 10,
        repeat: 0
    });

    scene.anims.create({
        key: 'big-guy_dead-ground',
        frames: scene.anims.generateFrameNames('big-guy', {
            start: 125,
            end: 128,
            prefix: 'big-guy-',
            suffix: '.aseprite'
        }),
        frameRate: 10,
        repeat: 0
    });

    // Captain
    scene.anims.create({
        key: 'captain_idle',
        frames: scene.anims.generateFrameNames('captain', {
            start: 0,
            end: 31,
            prefix: 'captain-',
            suffix: '.aseprite'
        }),
        frameRate: 20,
        repeat: -1
    });

    scene.anims.create({
        key: 'captain_run',
        frames: scene.anims.generateFrameNames('captain', {
            start: 32,
            end: 45,
            prefix: 'captain-',
            suffix: '.aseprite'
        }),
        frameRate: 10,
        repeat: -1
    });

    scene.anims.create({
        key: 'captain_jump-anticipation',
        frames: scene.anims.generateFrameNames('captain', {
            start: 46,
            end: 46,
            prefix: 'captain-',
            suffix: '.aseprite'
        }),
        frameRate: 10,
        repeat: 0
    });

    scene.anims.create({
        key: 'captain_jump',
        frames: scene.anims.generateFrameNames('captain', {
            start: 47,
            end: 50,
            prefix: 'captain-',
            suffix: '.aseprite'
        }),
        frameRate: 10,
        repeat: -1
    });

    scene.anims.create({
        key: 'captain_fall',
        frames: scene.anims.generateFrameNames('captain', {
            start: 51,
            end: 52,
            prefix: 'captain-',
            suffix: '.aseprite'
        }),
        frameRate: 10,
        repeat: -1
    });

    scene.anims.create({
        key: 'captain_ground',
        frames: scene.anims.generateFrameNames('captain', {
            start: 53,
            end: 55,
            prefix: 'captain-',
            suffix: '.aseprite'
        }),
        frameRate: 10,
        repeat: -1
    });

    scene.anims.create({
        key: 'captain_attack',
        frames: scene.anims.generateFrameNames('captain', {
            start: 56,
            end: 62,
            prefix: 'captain-',
            suffix: '.aseprite'
        }),
        frameRate: 10,
        repeat: 0
    });

    scene.anims.create({
        key: 'captain_scare-run',
        frames: scene.anims.generateFrameNames('captain', {
            start: 63,
            end: 74,
            prefix: 'captain-',
            suffix: '.aseprite'
        }),
        frameRate: 10,
        repeat: -1
    });

    scene.anims.create({
        key: 'captain_hit',
        frames: scene.anims.generateFrameNames('captain', {
            start: 75,
            end: 82,
            prefix: 'captain-',
            suffix: '.aseprite'
        }),
        frameRate: 10,
        repeat: 0
    });

    scene.anims.create({
        key: 'captain_dead-hit',
        frames: scene.anims.generateFrameNames('captain', {
            start: 83,
            end: 88,
            prefix: 'captain-',
            suffix: '.aseprite'
        }),
        frameRate: 10,
        repeat: 0
    });

    scene.anims.create({
        key: 'captain_dead-ground',
        frames: scene.anims.generateFrameNames('captain', {
            start: 89,
            end: 92,
            prefix: 'captain-',
            suffix: '.aseprite'
        }),
        frameRate: 10,
        repeat: 0
    });


    // Whale Animations
    scene.anims.create({
        key: 'whale_idle',
        frames: scene.anims.generateFrameNames('whale', {
            start: 0,
            end: 43,
            prefix: 'whale-',
            suffix: '.aseprite'
        }),
        frameRate: 20,
        repeat: -1
    });

    scene.anims.create({
        key: 'whale_run',
        frames: scene.anims.generateFrameNames('whale', {
            start: 44,
            end: 57,
            prefix: 'whale-',
            suffix: '.aseprite'
        }),
        frameRate: 10,
        repeat: -1
    });

    scene.anims.create({
        key: 'whale_jump-anticipation',
        frames: scene.anims.generateFrameNames('whale', {
            start: 58,
            end: 58,
            prefix: 'whale-',
            suffix: '.aseprite'
        }),
        frameRate: 10,
        repeat: 0
    });

    scene.anims.create({
        key: 'whale_jump',
        frames: scene.anims.generateFrameNames('whale', {
            start: 59,
            end: 62,
            prefix: 'whale-',
            suffix: '.aseprite'
        }),
        frameRate: 10,
        repeat: -1
    });

    scene.anims.create({
        key: 'whale_fall',
        frames: scene.anims.generateFrameNames('whale', {
            start: 63,
            end: 64,
            prefix: 'whale-',
            suffix: '.aseprite'
        }),
        frameRate: 10,
        repeat: -1
    });

    scene.anims.create({
        key: 'whale_ground',
        frames: scene.anims.generateFrameNames('whale', {
            start: 65,
            end: 67,
            prefix: 'whale-',
            suffix: '.aseprite'
        }),
        frameRate: 10,
        repeat: -1
    });

    scene.anims.create({
        key: 'whale_attack',
        frames: scene.anims.generateFrameNames('whale', {
            start: 68,
            end: 78,
            prefix: 'whale-',
            suffix: '.aseprite'
        }),
        frameRate: 10,
        repeat: 0
    });

    scene.anims.create({
        key: 'whale_swallow',
        frames: scene.anims.generateFrameNames('whale', {
            start: 79,
            end: 88,
            prefix: 'whale-',
            suffix: '.aseprite'
        }),
        frameRate: 10,
        repeat: 0
    });

    scene.anims.create({
        key: 'whale_hit',
        frames: scene.anims.generateFrameNames('whale', {
            start: 89,
            end: 95,
            prefix: 'whale-',
            suffix: '.aseprite'
        }),
        frameRate: 10,
        repeat: 0
    });

    scene.anims.create({
        key: 'whale_dead-hit',
        frames: scene.anims.generateFrameNames('whale', {
            start: 96,
            end: 101,
            prefix: 'whale-',
            suffix: '.aseprite'
        }),
        frameRate: 10,
        repeat: 0
    });

    scene.anims.create({
        key: 'whale_dead-ground',
        frames: scene.anims.generateFrameNames('whale', {
            start: 102,
            end: 105,
            prefix: 'whale-',
            suffix: '.aseprite'
        }),
        frameRate: 10,
        repeat: 0
    });


    // Objects
    scene.anims.create({
        key: 'Closed',
        frames: scene.anims.generateFrameNames('objects', {
            start: 0,
            end: 0,
            prefix: '7-Objects ',
            suffix: '.aseprite'
        }),
        frameRate: 10,
        repeat: 0
    });

    // Window Light
    scene.anims.create({
        key: 'window-light',
        frames: scene.anims.generateFrameNames('objects', {
            start: 69,
            end: 72,
            prefix: '7-Objects ',
            suffix: '.aseprite'
        }),
        frameRate: 10,
        repeat: -1
    });

    // Candle Light
    scene.anims.create({
        key: 'candle-light',
        frames: scene.anims.generateFrameNames('objects', {
            start: 64,
            end: 68,
            prefix: '7-Objects ',
            suffix: '.aseprite'
        }),
        frameRate: 10,
        repeat: -1
    });

    // Candle
    scene.anims.create({
        key: 'candle',
        frames: scene.anims.generateFrameNames('objects', {
            start: 36,
            end: 43,
            prefix: '7-Objects ',
            suffix: '.aseprite'
        }),
        frameRate: 10,
        repeat: -1
    });
}
