import tileImg from '@assets/tiles.png';
import level1Json from '@assets/level-1.json';
import preloadJson from '@assets/preload.json';
import playerImg from '@assets/players/player.png';
import playerJson from '@assets/players/player.json';
import boldPirateImg from '@assets/players/bold-pirate.png';
import boldPirateJson from '@assets/players/bold-pirate.json';
import cucumberImg from '@assets/players/cucumber.png';
import cucumberJson from '@assets/players/cucumber.json';
import bigGuyImg from '@assets/players/big-guy.png';
import bigGuyJson from '@assets/players/big-guy.json';
import captainImg from '@assets/players/captain.png';
import captainJson from '@assets/players/captain.json';
import whaleImg from '@assets/players/whale.png';
import whaleJson from '@assets/players/whale.json';
import objectsJson from '@assets/objects.json';
import objectsImg from '@assets/objects.png';
import explosionMp3 from '@assets/audio/explosion.mp3';
import fizzMp3 from '@assets/audio/fizz.mp3';
import kickMp3 from '@assets/audio/kick.mp3';
import hurtMp3 from '@assets/audio/hurt.mp3';
import pirateHurtMp3 from '@assets/audio/pirate-hurt.mp3';
import jumpMp3 from '@assets/audio/jump.mp3';
import winMp3 from '@assets/audio/win.mp3';
import looseMp3 from '@assets/audio/loose.mp3';
import themeMp3 from '@assets/audio/clock-waltz.mp3';
import piratesThemeMp3 from '@assets/audio/pirates-theme.mp3';
import piratesTheme2Mp3 from '@assets/audio/pirates-theme-2.mp3';
import embitteredThemeMp3 from '@assets/audio/embittered - ceaseless depths.mp3';

export function loadAssets(scene) {
    scene.load.tilemapTiledJSON('map', level1Json);
    scene.load.tilemapTiledJSON('preload', preloadJson);
    scene.load.image('tiles', tileImg);
    scene.load.atlas('player', playerImg, playerJson);
    scene.load.atlas('bold-pirate', boldPirateImg, boldPirateJson);
    scene.load.atlas('cucumber', cucumberImg, cucumberJson);
    scene.load.atlas('big-guy', bigGuyImg, bigGuyJson);
    scene.load.atlas('captain', captainImg, captainJson);
    scene.load.atlas('whale', whaleImg, whaleJson);
    scene.load.atlas('objects', objectsImg, objectsJson);

    // Load audio assets
    scene.load.audio('bombExplosion', explosionMp3);
    scene.load.audio('bombFizz', fizzMp3);
    scene.load.audio('kickBomb', kickMp3);
    scene.load.audio('hurt', hurtMp3);
    scene.load.audio('pirateHurt', pirateHurtMp3);
    scene.load.audio('jump', jumpMp3);
    scene.load.audio('win', winMp3);
    scene.load.audio('loose', looseMp3);
    scene.load.audio('theme', themeMp3);
    scene.load.audio('piratesTheme', piratesThemeMp3);
    scene.load.audio('piratesTheme2', piratesTheme2Mp3);
    scene.load.audio('embitteredTheme', embitteredThemeMp3);
}
