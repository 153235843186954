// src/scenes/MainScene.js
import Phaser from 'phaser';
import Player from '@/objects/Player';
import Character from '@/objects/Character';
import Bomb from '@/objects/Bomb';
import { loadAssets } from '@/utils/AssetLoader';
import SocketManager from '@/utils/SocketManager';

export default class MainScene extends Phaser.Scene {
    constructor() {
        super('MainScene');
        this.otherPlayers = {};
        this.countdownTimer = 30;
        this.currentThemeSong = 'theme';
        this.themeSongs = [
            {
                key: 'theme',
                title: 'Pirate Bomb',
            },
            {
                key: 'piratesTheme2',
                title: 'Pirate Bomb v2',
            },
            {
                key: 'piratesTheme',
                title: 'Pirates of the Caribbean',
            },
            {
                key: 'embitteredTheme',
                title: 'Embittered - Ceaseless Depths',
            }
        ];
    }

    preload() {
        loadAssets(this);
    }

    create() {
        // Play the theme song
        this.sound.play(this.currentThemeSong, {
            loop: true, // Set to true if you want the theme song to loop
            volume: 0.5 // Adjust the volume as needed
        });
        this.canSwitchSong = true;

        this.setupMapAndObjects();

        let randomSpawnPoints = [
            {
                x: 200,
                y: 170
            },
            {
                x: 600,
                y: 170
            },
            {
                x: 800,
                y: 170
            }
        ];

        let randomIndex = Math.floor(Math.random() * randomSpawnPoints.length);

        // Create the player
        this.player = new Player(this, randomSpawnPoints[randomIndex].x, randomSpawnPoints[randomIndex].y);

        // Create bomb
        this.bomb = new Bomb(this, 450, 300);

        // Add colliders
        this.physics.add.collider(this.player.sprite, this.foregroundLayer);
        this.physics.add.collider(this.player.sprite, this.platformsLayer);
        this.physics.add.collider(this.bomb.sprite, this.foregroundLayer);
        this.physics.add.collider(this.bomb.sprite, this.platformsLayer);
        this.physics.add.overlap(this.player.sprite, this.piano, this.switchThemeSong, null, this);

        // Add the player - bomb collider
        this.playerBombCollider = this.physics.add.collider(
            this.player.sprite,
            this.bomb.sprite,
            this.kickBomb,
            null,
            this
        );

        this.setupUI();
        this.setupCamera();

        // Initialize the socket manager
        this.socketManager = new SocketManager(this);
        this.player.setSocketManager(this.socketManager);

        // Create sounds
        this.sound.add('bombExplosion');
        this.sound.add('bombFizz');
        this.sound.add('kickBomb');
        this.sound.add('hurt');
        this.sound.add('pirateHurt');
        this.sound.add('win');
        this.sound.add('loose');
        this.events.on('playerDamaged', (health) => {
            this.updateHearts(health);
        });
        //this.game.sound.stopAll();
    }

    setupMapAndObjects() {
        // Load map and create layers
        const map = this.make.tilemap({ key: 'map' });
        const tileset = map.addTilesetImage('Tile-Sets (64-64)', 'tiles');

        this.backgroundLayer = map.createLayer('background', tileset, 0, 0);
        this.platformsLayer = map.createLayer('platforms', tileset, 0, 0);
        this.foregroundLayer = map.createLayer('foreground', tileset, 0, 0);

        // parallax foreground
        this.backgroundLayer.setScrollFactor(.9);

        this.foregroundLayer.setCollisionByExclusion([-1]);
        this.platformsLayer.setCollisionByExclusion([-1]);

        // Add objects
        let door = this.add.image(128 * 6, 128, 'objects', '7-Objects 0.aseprite').setOrigin(0, 0); // dir
        let window1 = this.add.image(128 * 2, 90, 'objects', '7-Objects 102.aseprite').setOrigin(0, 0).setScrollFactor(0.9); // window 1
        let window2 = this.add.image(128 * 3, 90, 'objects', '7-Objects 102.aseprite').setOrigin(0, 0).setScrollFactor(0.9); // window 2

        const windowLight1 = this.add.sprite(128 * 2 + 68, 150, 'objects', '7-Objects 69.aseprite')
            .setOrigin(0, 0)
            .play('window-light')
            .setScrollFactor(0.9);
        const windowLight2 = this.add.sprite(128 * 3 + 68, 150, 'objects', '7-Objects 69.aseprite')
            .setOrigin(0, 0)
            .play('window-light')
            .setScrollFactor(0.9);

        let candle = this.add.sprite(128 * 7 + 60, 250, 'objects', '7-Objects 64.aseprite')
            .setOrigin(0, 0)
            .play('candle')
            .setScrollFactor(0.9);
        let candleLight = this.add.sprite(128 * 7 + 60, 230, 'objects', '7-Objects 64.aseprite')
            .setOrigin(0, 0)
            .play('candle-light')
            .setScrollFactor(0.9);

        this.add.image(64 * 2, 64 * 2 + 15, 'objects', '7-Objects 101.aseprite').setOrigin(0, 0); // barrel
        this.add.image(64 + 10, 64 * 2 + 15, 'objects', '7-Objects 101.aseprite').setOrigin(0, 0);
        this.add.image(64 * 6 - 10, 64 * 7 + 15, 'objects', '7-Objects 96.aseprite').setOrigin(0, 0); // table
        this.add.image(64 * 3 + 100, 64 * 7 + 15, 'objects', '7-Objects 95.aseprite').setOrigin(0, 0); // chair

        this.piano = this.add.image(64 * 2 + 40, 64 * 7 - 0, 'objects', '7-Objects 158.aseprite').setOrigin(0, 0); // piano
        this.physics.world.enable(this.piano); // Enable physics on the piano
        this.piano.body.setImmovable(true);
        this.piano.body.setAllowGravity(false); // Disable gravity on the piano
        // Inside your create method or after creating the piano object


        this.platformsLayer.forEachTile(tile => {
            if (tile.index !== -1) { // -1 means no tile is present
                tile.setCollision(false, false, true, false); // Enable collision top only
            }
        });
    }

    setupCamera() {

        this.uiCamera = this.cameras.add(0, 0, this.cameras.main.width, this.cameras.main.height);
        this.uiCamera.ignore(this.children.list.filter(child =>
            child !== this.timerText &&
            child !== this.heartContainer &&
            child !== this.scoreboardText &&
            child !== this.leftButton &&
            child !== this.rightButton &&
            child !== this.jumpButton &&
            !this.hearts.includes(child)
        ));

        this.cameras.main.startFollow(this.player.sprite);

        const ignoreObjects = [
            this.timerText,
            this.heartContainer,
            ...(this.sys.game.device.os.desktop ? [] : [this.leftButton, this.rightButton, this.jumpButton])
        ];

        this.cameras.main.ignore(ignoreObjects);
        this.cameras.main.setZoom(1.5);
    }

    setupUI() {
        this.timerText = this.add.text(
            50,
            50,
            `Connecting...`, {
            fontSize: '24px',
            fill: '#fff'
        });
        this.timerText.setScrollFactor(0);

        // Add the heart container image in the top-right corner
        this.heartContainer = this.add.image(
            this.cameras.main.width - 90,
            50, 'objects', '7-Objects 93.aseprite');
        this.heartContainer.setScrollFactor(0); // Make the image static on the screen

        // Add three hearts next to the heart container
        this.hearts = [];
        const heartSpacing = 25; // Adjust spacing between hearts

        for (let i = 0; i < 3; i++) {
            const heart = this.add.image(
                this.heartContainer.x - 25 + i * heartSpacing, // Position each heart relative to the container
                this.heartContainer.y,
                'objects', '7-Objects 94.aseprite'
            );
            heart.setScrollFactor(0); // Make the hearts static on the screen
            this.hearts.push(heart); // Store the heart in an array for future reference
        }

        // Set up the scoreboard display (list of wins)
        this.scoreboardText = this.add.text(
            this.cameras.main.width - 0, // minus size of scoreboardText
            100, // Position below the timer text
            '', {
            fontSize: '18px',
            fill: '#fff'
        });
        this.scoreboardText.setScrollFactor(0);

        if (!this.sys.game.device.os.desktop) {
            this.createMobileControls();
        }
    }

    update() {
        this.player.update();
        this.bomb.update();
        Object.values(this.otherPlayers).forEach(player => {
            player.update();
        });
    }

    addOtherPlayer(playerInfo) {
        const character = new Character(this, playerInfo.x, playerInfo.y, playerInfo.skin);
        character.usernameText = this.add.text(playerInfo.x, playerInfo.y - 30, playerInfo.username, {
            fontSize: '16px',
            fill: '#fff'
        }).setOrigin(0.5);

        this.otherPlayers[playerInfo.id] = character;
        this.uiCamera.ignore(character.sprite);
        this.uiCamera.ignore(character.usernameText);

        // Add colliders for the new character
        this.physics.add.collider(character.sprite, this.foregroundLayer);
        this.physics.add.collider(character.sprite, this.platformsLayer);
    }

    moveOtherPlayer(playerInfo) {
        const character = this.otherPlayers[playerInfo.id];
        if (character) {
            // Use a tween to interpolate the movement smoothly
            this.tweens.add({
                targets: character.sprite,
                x: playerInfo.x,
                y: playerInfo.y,
                ease: 'Linear',
                duration: 50, // Adjust this duration based on your game's requirements
                onComplete: () => {
                    // Set final position to ensure accuracy
                    character.sprite.setPosition(playerInfo.x, playerInfo.y);
                }
            });

            // flip
            if (playerInfo.flipX !== undefined) {
                character.sprite.setFlipX(playerInfo.flipX);
            }

            // Update animation state
            if (playerInfo.animation && character.sprite.anims.currentAnim?.key !== playerInfo.animation) {
                character.sprite.anims.play(playerInfo.animation, true);
            }
        }
    }

    removeOtherPlayer(playerId) {
        const character = this.otherPlayers[playerId];
        if (character) {
            character.sprite.destroy(); // Remove the sprite
            delete this.otherPlayers[playerId]; // Remove the reference
            // remove character text
            character.usernameText.destroy();
        }
    }

    kickBomb(player, bomb) {

        if (bomb.isExploding) {
            return;
        }

        const currentTime = this.time.now; // Get the current time
        const kickCooldown = 500; // Cooldown time in milliseconds

        // Prevent continuous kicking if the player is standing on the bomb
        if (currentTime - bomb.lastKickTime < kickCooldown) {
            return; // Exit if the cooldown hasn't expired
        }

        // Update the last kick time
        bomb.lastKickTime = currentTime;

        // play sound
        this.sound.play('kickBomb');

        // Check if on explosion animation
        if (this.bomb.sprite.anims.currentAnim.key === 'bomb_explosion') {
            return;
        }

        const kickStrength = this.player.kickStrength;
        const direction = player.flipX ? -1 : 1;

        // Prevent the bomb from floating up when the player is standing on it
        if (player.body.touching.down && bomb.body.touching.up) {
            return; // Do nothing if the player is standing on the bomb
        }

        // Check if the player is hitting the bomb from below
        if (player.body.touching.up && bomb.body.touching.down) {
            bomb.setVelocityY(-kickStrength * 1.5); // Apply an upward force to the bomb
        } else {
            // Apply force to the bomb horizontally
            bomb.setVelocityX(direction * kickStrength);
            bomb.setVelocityY(-kickStrength); // Apply some upward force
        }

        // Emit the bomb's new state to the server using SocketManager
        this.socketManager.emitKickBomb(bomb);
    }

    startCountdown() {
        this.time.addEvent({
            delay: 1000,
            callback: this.updateTimer,
            callbackScope: this,
            loop: true
        });
    }

    updateTimer() {
        this.countdownTimer--;

        if (this.countdownTimer <= 0) {
            this.timerText.setText('Time: 0');
        } else {
            this.timerText.setText(`Time: ${this.countdownTimer}`);
        }
    }

    updateCountdown(seconds, state) {

        let timerText;

        if (state === 'waiting') {
            timerText = `Waiting for players...`;
            if (seconds > 0) {
                timerText += `(${seconds})`;
            }
        } else {
            timerText = `Time: ${seconds}`;
        }
        this.timerText.setText(timerText);
        // Optional: Add some visual effects when the countdown is low
        if (seconds <= 10 && state === 'playing') {
            this.timerText.setStyle({ fill: '#ff0000' }); // Change the text color to red
            this.timerText.setScale(1.5); // Increase the text size
            this.tweens.add({
                targets: this.timerText,
                scaleX: 1,
                scaleY: 1,
                duration: 500,
                ease: 'Elastic',
                yoyo: true
            });
        } else {
            this.timerText.setStyle({ fill: '#ffffff' }); // Reset the text color to white
            this.timerText.setScale(1); // Reset the text size
        }
    }

    createMobileControls() {
        this.input.addPointer(3);
        // Create a left movement button
        this.leftButton = this.add.rectangle(60, this.cameras.main.height - 60, 100, 100, 0x0000ff, 0.5)
            .setOrigin(0.5)
            .setInteractive()
            .on('pointerdown', () => { this.player.moveLeft(); })
            .on('pointerup', () => { this.player.stop(); });

        // Create a right movement button
        this.rightButton = this.add.rectangle(180, this.cameras.main.height - 60, 100, 100, 0x0000ff, 0.5)
            .setOrigin(0.5)
            .setInteractive()
            .on('pointerdown', () => { this.player.moveRight(); })
            .on('pointerup', () => { this.player.stop(); });

        // Create a jump button
        this.jumpButton = this.add.rectangle(this.cameras.main.width - 60, this.cameras.main.height - 60, 100, 100, 0x00ff00, 0.5)
            .setOrigin(0.5)
            .setInteractive()
            .on('pointerdown', () => { this.player.jump(); });

        // Create an action button (e.g., kick bomb)
        // this.actionButton = this.add.rectangle(this.cameras.main.width - 180, this.cameras.main.height - 60, 100, 100, 0xff0000, 0.5)
        //     .setOrigin(0.5)
        //     .setInteractive()
        //     .on('pointerdown', () => { this.player.kickBomb(this.player.sprite, this.bomb.sprite); });

        // Fix the buttons to the camera's view
        this.leftButton.setScrollFactor(0);
        this.rightButton.setScrollFactor(0);
        this.jumpButton.setScrollFactor(0);
        //this.actionButton.setScrollFactor(0);
    }

    updateHearts(health) {
        for (let i = 0; i < this.hearts.length; i++) {
            if (i < health) {
                this.hearts[i].setVisible(true); // Show the heart if within health
            } else {
                this.hearts[i].setVisible(false); // Hide the heart if beyond health
            }
        }
    }

    updateScoreboard(players) {
        let scoreboardContent = 'Scores:\n';
        Object.values(players).forEach(player => {
            scoreboardContent += `${player.username}: ${player.wins} wins\n`;
        });
        this.scoreboardText.setText(scoreboardContent); // Update the scoreboard text

        // Reposition the scoreboardText to the right edge of the screen
        this.scoreboardText.setX(this.cameras.main.width - this.scoreboardText.width - 50);
    }

    switchThemeSong(player, piano) {
        if (!this.canSwitchSong) {
            return;
        }

        this.canSwitchSong = false;

        // Stop the current theme song
        this.sound.stopByKey(this.currentThemeSong);

        // Cycle to the next theme song
        const currentSongIndex = this.themeSongs.findIndex(song => song.key === this.currentThemeSong);
        const nextSongIndex = (currentSongIndex + 1) % this.themeSongs.length;
        this.currentThemeSong = this.themeSongs[nextSongIndex].key;

        // Play the new theme song
        this.sound.play(this.currentThemeSong, {
            loop: true,
            volume: 0.5
        });

        // Display the title of the new song in the middle of the screen
        const text = this.add.text(
            this.cameras.main.width / 2, // Center horizontally
            this.cameras.main.height / 2 - 100, // Center vertically
            `${this.themeSongs[nextSongIndex].title}`,
            {
                fontSize: '24px',
                fill: '#fff'
            }
        );

        text.setOrigin(0.5); // Center the text based on its origin
        text.setScrollFactor(0); // Make the text static on the screen

        this.cameras.main.ignore(text); // Ignore the text when following the player

        // Make the text disappear after a short duration
        this.time.addEvent({
            delay: 2000, // Duration in milliseconds (2 seconds)
            callback: () => {
                text.destroy(); // Remove the text from the scene
                this.canSwitchSong = true;
            },
            callbackScope: this
        });
    }
}
