// src/GameConfig.js
import MainScene from '@/scenes/MainScene';  // Import the MainScene
import Preloader from '@/scenes/Preloader'; // Import the Preloader
import MainMenu from '@/scenes/MainMenu';     // Import the MainMenu (if you have one)

export const config = {
    type: Phaser.AUTO,
    width: 1280,
    height: 720,
    backgroundColor: '#323443',
    pixelArt: true,
    scale: {
        mode: Phaser.Scale.FIT,
        autoCenter: Phaser.Scale.CENTER_BOTH,
        width: 1280,
        height: 720,
    },
    physics: {
        default: 'arcade',
        arcade: {
            gravity: { y: 1000 },
            debug: false
        }
    },
    scene: [Preloader, MainMenu, MainScene]
};
