// src/scenes/MainMenu.js
import Phaser from 'phaser';
import { createAnimations } from '@/animations/Animations';

export default class MainMenu extends Phaser.Scene {
    constructor() {
        super('MainMenu');
    }

    create() {

        const map = this.make.tilemap({ key: 'preload' });
        const tileset = map.addTilesetImage('Tile-Sets (64-64)', 'tiles');
        this.backgroundLayer = map.createLayer('background', tileset, 0, 0);

        createAnimations(this);
        this.setUpCamera();

        this.sprite = this.physics.add.sprite(
            160,
            230,
            'player'
        ).play('idle');
        this.sprite.body.setAllowGravity(false);

        const username = this.registry.get('username');
        this.usernameText = this.add.text(this.sprite.x, this.sprite.y - 40, username, {
            fontSize: '22px',
            fill: '#fff'
        }).setOrigin(0.5).setInteractive();
        // Add interactivity to the username text
        this.usernameText.on('pointerup', () => {
            this.promptUsernameChange();
        });

        // Add a "Start Game" button
        const startButton = this.add.text(this.cameras.main.width / 2, this.cameras.main.height / 2, 'Start Game', {
            fontSize: '46px',
            fill: '#fff'
        }).setOrigin(0.5).setInteractive();

        startButton.on('pointerup', () => {
            this.scene.start('MainScene');
        });

        this.showLeaderboard();

        this.uiCamera.ignore(this.sprite);
        this.uiCamera.ignore(this.backgroundLayer);
        this.uiCamera.ignore(this.usernameText);
        this.cameras.main.ignore(startButton);
    }

    setUpCamera() {
        const map = this.make.tilemap({ key: 'preload' });
        const mapWidth = map.widthInPixels;
        const mapHeight = map.heightInPixels;

        // Calculate the center of the map
        const mapCenterX = mapWidth / 2;
        const mapCenterY = mapHeight / 2;

        // Center the camera on the map center
        this.cameras.main.centerOn(mapCenterX, mapCenterY);
        this.cameras.main.setZoom(2.3);

        this.uiCamera = this.cameras.add(0, 0, this.cameras.main.width, this.cameras.main.height);
    }

    promptUsernameChange() {
        const newUsername = prompt('Enter your new username:', this.registry.get('username'));
        if (newUsername && newUsername !== this.registry.get('username')) {
            this.updateUsername(newUsername);
        }
    }

    updateUsername(newUsername) {
        const deviceUUID = this.registry.get('deviceUUID');

        fetch(process.env.API_URL + `/register.php`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ username: newUsername, deviceUUID })
        })
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    this.registry.set('username', newUsername);
                    this.usernameText.setText(newUsername); // Update the displayed username
                } else {
                    console.error('Failed to update username:', data.message);
                }
            })
            .catch(error => {
                console.error('Error updating username:', error);
            });
    }

    showLeaderboard() {
        const deviceUUID = this.registry.get('deviceUUID');

        fetch(process.env.API_URL + `/get-scores.php`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ deviceUUID })
        })
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    this.displayLeaderboard(data.topPlayers);
                    this.displayCurrentPlayer(data.currentPlayer);
                } else {
                    console.error('Failed to fetch leaderboard:', data.message);
                }
            })
            .catch(error => {
                console.error('Error fetching leaderboard:', error);
            });
    }

    displayLeaderboard(topPlayers) {
        // Clear any existing leaderboard display
        if (this.leaderboardText) {
            this.leaderboardText.destroy();
        }

        // Prepare the leaderboard content
        let leaderboardContent = 'Leaderboard:\n\n';
        topPlayers.forEach((player, index) => {
            leaderboardContent += `${index + 1}. ${player.username}: ${player.score}\n`;
        });

        // Display the leaderboard on the right side of the screen
        this.leaderboardText = this.add.text(this.cameras.main.width - 200, 50, leaderboardContent, {
            fontSize: '22px',
            fill: '#fff',
            align: 'right'
        }).setOrigin(0.5, 0);

        // Ensure the leaderboard is visible on the main camera
        this.cameras.main.ignore(this.leaderboardText);
    }

    displayCurrentPlayer(currentPlayer) {
        // Clear any existing current player display
        if (this.currentPlayerText) {
            this.currentPlayerText.destroy();
        }

        // Display the current player's score and rank
        const playerContent = `Your Score: ${currentPlayer.score}\nYour Rank: ${currentPlayer.rank}`;

        this.currentPlayerText = this.add.text(50, this.cameras.main.height - 100, playerContent, {
            fontSize: '22px',
            fill: '#fff',
            align: 'left'
        }).setOrigin(0, 0);

        // Ensure the current player info is visible on the main camera
        this.cameras.main.ignore(this.currentPlayerText);
    }

}
