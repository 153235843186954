// src/objects/Player.js
import Phaser from 'phaser';

export default class Player {
    constructor(scene, x, y) {
        this.scene = scene;
        this.sprite = scene.physics.add.sprite(x, y, 'player').play('idle');
        this.sprite.setCollideWorldBounds(true);
        this.sprite.body.setSize(20, 50);
        //this.sprite.body.setOffset(10, 8);

        this.health = 3; // Players can get hit twice before dying
        this.isDead = false;
        this.isJumping = false;
        this.jumpBoost = 0;
        this.maxJumpBoost = 200;
        this.jumpChargeRate = 10;
        this.kickStrength = 400;

        this.cursors = scene.input.keyboard.createCursorKeys();
        this.spacebar = scene.input.keyboard.addKey(Phaser.Input.Keyboard.KeyCodes.SPACE); // Add spacebar for pick up/drop

        // Track previous velocities
        this.prevVelocityX = 0;
        this.prevVelocityY = 0;
        this.damageCooldown = false; // Cooldown flag
        this.damageCooldownTime = 1000;

        const username = this.scene.registry.get('username');
        this.usernameText = this.scene.add.text(x, y - 30, username, {
            fontSize: '16px',
            fill: '#fff'
        }).setOrigin(0.5);

        this.carryingBomb = false; // New property to track if the player is carrying the bomb
    }

    update() {
        this.usernameText.setPosition(this.sprite.x, this.sprite.y - 40);

        if (this.isDead || this.damageCooldown) {
            return;
        }

        let moved = false;

        if (this.jumpBoost === 0) {
            if (this.cursors.left.isDown) {
                this.sprite.setVelocityX(-260);
                if (this.sprite.body.blocked.down) {
                    this.sprite.anims.play('run', true);
                }
                this.sprite.setFlipX(true);
                moved = true;
            } else if (this.cursors.right.isDown) {
                this.sprite.setVelocityX(260);
                if (this.sprite.body.blocked.down) {
                    this.sprite.anims.play('run', true);
                }
                this.sprite.setFlipX(false);
                moved = true;
            } else {
                this.sprite.setVelocityX(0);
                this.sprite.anims.play('idle', true);
            }
        }

        if (this.cursors.up.isDown && this.sprite.body.blocked.down) {
            this.sprite.setVelocityY(-400);
            this.sprite.anims.play('jump', true);
            this.scene.sound.play('jump', {
                volume: 0.5
            });
            moved = true;
        }

        if (this.cursors.down.isDown && this.sprite.body.blocked.down) {
            this.sprite.anims.play('jump-anticipation', true);
            this.jumpBoost = Math.min(this.jumpBoost + this.jumpChargeRate, this.maxJumpBoost);
            this.isJumping = true;
            this.sprite.setVelocityX(0);
        } else if (this.isJumping && !this.cursors.down.isDown) {
            this.isJumping = false;
            this.sprite.setVelocityY(-400 - this.jumpBoost);
            this.sprite.anims.play('jump', true);
            this.scene.sound.play('jump', {
                volume: 0.5
            });
            this.jumpBoost = 0;
            moved = true;
        }

        // Check if player is carrying the bomb
        if (this.carryingBomb && Phaser.Input.Keyboard.JustDown(this.spacebar)) {
            // Drop the bomb

            const kickStrength = this.sprite.body.velocity.x !== 0 ? this.kickStrength : 0;
            const direction = this.sprite.flipX ? -1 : 1;
            const isPlayerJumping = this.sprite.body.velocity.y < 0;

            this.scene.bomb.dropBomb(
                this.sprite.x,
                this.sprite.y,
                kickStrength,
                direction,
                isPlayerJumping,
                this.socketManager
            );
            this.carryingBomb = false;
        }

        // Check for picking up the bomb
        if (!this.carryingBomb && Phaser.Input.Keyboard.JustDown(this.spacebar)) {
            const distance = Phaser.Math.Distance.Between(this.sprite.x, this.sprite.y, this.scene.bomb.sprite.x, this.scene.bomb.sprite.y);
            if (distance < 70) { // Adjust the range based on your game's needs
                this.scene.bomb.pickUp(this);
                this.carryingBomb = true;
            }
        }

        const currentVelocityX = this.sprite.body.velocity.x;
        const currentVelocityY = this.sprite.body.velocity.y;

        // Emit player movement event if moved
        if (moved ||
            currentVelocityX !== this.prevVelocityX ||
            currentVelocityY !== this.prevVelocityY ||
            this.cursors.down.isDown
        ) {
            this.socketManager.emitPlayerMove({
                x: this.sprite.x,
                y: this.sprite.y,
                velocityX: this.sprite.body.velocity.x,
                velocityY: this.sprite.body.velocity.y,
                animation: this.sprite.anims.currentAnim ? this.sprite.anims.currentAnim.key : 'idle',
                flipX: this.sprite.flipX,
                id: this.scene.socketId
            });
        }

        // Track the previous velocities
        this.prevVelocityX = currentVelocityX;
        this.prevVelocityY = currentVelocityY;
    }

    takeDamage() {

        if (this.isDead || this.damageCooldown) {
            return;
        }

        this.scene.sound.play('hurt');
        this.sprite.anims.play('hit', true);
        this.health -= 1;

        // Emit an event to update the hearts UI in the MainScene
        this.scene.events.emit('playerDamaged', this.health);

        this.socketManager.emitPlayerHit({
            id: this.scene.socketId,
            health: this.health
        });

        if (this.health > 0) {
            this.sprite.anims.play('hit', true); // Play hit animation
        } else if (this.health <= 0) {
            this.die();
        }

        // Start cooldown to prevent multiple damage instances
        this.damageCooldown = true;
        this.scene.time.delayedCall(this.damageCooldownTime, () => {
            this.damageCooldown = false;
        });
    }

    // Manually trigger cursor key presses
    moveLeft() {
        this.cursors.left.isDown = true;
    }

    moveRight() {
        this.cursors.right.isDown = true;
    }

    jump() {
        if (!this.isDead && this.sprite.body.blocked.down) {
            this.sprite.setVelocityY(-400);
            this.sprite.anims.play('jump', true);
            this.isJumping = true;
        }
    }

    stop() {
        this.cursors.left.isDown = false;
        this.cursors.right.isDown = false;
        this.cursors.up.isDown = false;
        this.cursors.down.isDown = false;
    }

    setSocketManager(socketManager) {
        this.socketManager = socketManager;
    }

    die() {
        this.isDead = true;
        this.sprite.anims.play('dead-hit', true);
        //this.sprite.body.setSize(40, 40);

        // Send Player flying
        this.sprite.setVelocityY(-500);
        this.sprite.setVelocityX(this.sprite.flipX ? 500 : -500);

        this.sprite.once('animationcomplete', (animation, frame) => {
            if (animation.key === 'dead-hit') {
                this.sprite.anims.play('dead-ground', true);
            }
        });
    }
}
