// src/utils/SocketManager.js
import io from 'socket.io-client';

export default class SocketManager {
    constructor(scene) {
        this.scene = scene;
        this.socket = io(process.env.SOCKET_DOMAIN);

        this.socket.on('connect', () => {
            console.log('Connected to server', this.socket.id);
            this.scene.socketId = this.socket.id;
            this.socket.emit('playerJoin', {
                x: this.scene.player.sprite.x,
                y: this.scene.player.sprite.y,
                username: this.scene.registry.get('username')
            });
        });

        // This line informs all other connected clients that a new player has joined the game.
        this.socket.on('newPlayer', (data) => {
            const { player, players } = data;
            this.scene.addOtherPlayer(player);
            if (player.dead) {
                this.scene.otherPlayers[player.id].die();
            }

            this.scene.updateScoreboard(players);
        });

        // This line informs the newly connected player about all the players that are already in the game.
        this.socket.on('existingPlayers', (players) => {

            Object.values(players).forEach(playerInfo => {
                if (playerInfo.id !== this.scene.socketId) {
                    this.scene.addOtherPlayer(playerInfo);
                }

                if (playerInfo.dead) {
                    playerInfo.id === this.scene.socketId ?
                        this.scene.player.die() :
                        this.scene.otherPlayers[playerInfo.id].die();
                }
            });

            this.scene.updateScoreboard(players);
        });

        this.socket.on('playerMoved', (playerInfo) => {
            this.scene.moveOtherPlayer(playerInfo);
        });

        this.socket.on('playerDisconnected', (playerId) => {
            this.scene.removeOtherPlayer(playerId);
        });

        this.socket.on('spawnBomb', (bomb) => {
            this.scene.bomb.sprite.x = bomb.x;
            this.scene.bomb.sprite.y = bomb.y;
            this.scene.bomb.sprite.setVelocity(bomb.velocityX, bomb.velocityY);
            this.scene.bomb.sprite.play('bomb_off');
            this.scene.bomb.sprite.setVisible(true);
            this.scene.bomb.sprite.body.enable = true;
            this.scene.bomb.state = 'idle';
        });

        this.socket.on('bombState', (bomb) => {
            this.scene.bomb.updateBomb(bomb);
        });

        this.socket.on('bombIgnited', () => {
            this.scene.bomb.ignite();
        });

        this.socket.on('bombExploded', () => {
            this.scene.bomb.explode();
        });

        this.socket.on('startGame', (players) => {
            this.scene.bomb.sprite.setVisible(true); // Show the bomb
            this.scene.bomb.sprite.body.enable = true;
            this.scene.bomb.sprite.play('bomb_off');
            this.scene.canSwitchSong = false;
        });

        this.socket.on('startWaiting', (playerDefaultHealth) => {
            this.scene.player.sprite.anims.play('idle', true);
            this.scene.player.isDead = false;
            //this.scene.player.sprite.body.setSize(40, 50);
            this.scene.player.health = playerDefaultHealth;
            this.scene.updateHearts(playerDefaultHealth);
            this.scene.bomb.hideBomb();

            // Reset other players
            Object.values(this.scene.otherPlayers).forEach(player => {
                player.sprite.anims.play(player.skin + '_idle', true);
                player.isDead = false;
                player.health = playerDefaultHealth;
                //player.sprite.body.setSize(player.defaultSize.width, player.defaultSize.height);
            });

            this.scene.canSwitchSong = true;
        });

        this.socket.on('endGame', (data) => {
            const { result, players, deadPlayers } = data;

            if (!this.scene.bomb.isHidden() && this.scene.bomb.state === 'idle') {
                this.scene.bomb.explode();
            }

            // Display the game result
            if (result.winnerId === this.scene.socketId) {
                this.scene.sound.play('win');
                this.scene.timerText.setColor('#00ff00');
                // Update the database with the new score for the winning player
                this.updateApiPlayerScore(deadPlayers + 1); // dead players are extra points plus point for winning round
            } else {
                this.scene.sound.play('loose');
                this.scene.timerText.setColor('#ff0000');
            }
            this.scene.timerText.setText(result.message);
            this.scene.updateScoreboard(players);
        });

        this.socket.on('countdownUpdate', (data) => {
            this.scene.updateCountdown(data.time, data.state);
        });

        this.socket.on('playerHit', (playerInfo) => {
            const player = this.scene.otherPlayers[playerInfo.id];
            if (player) {
                player.health = playerInfo.health;

                if (playerInfo.id === this.scene.socketId) {
                    return;
                }
                if (player.health > 0) {
                    player.sprite.anims.play(playerInfo.skin + '_hit', true);
                } else if (player.health <= 0) {
                    player.sprite.anims.play(playerInfo.skin + '_dead-hit', true);
                }
            }
        });
    }

    emitKickBomb(bomb) {
        this.socket.emit('kickBomb', {
            x: bomb.x,
            y: bomb.y,
            velocityX: bomb.body.velocity.x,
            velocityY: bomb.body.velocity.y
        });
    }

    emitPlayerMove(data) {
        this.socket.emit('playerMove', data);
    }

    emitPlayerHit(data) {
        this.socket.emit('playerHit', data);
    }

    emitBombExplodedComplete() {
        this.socket.emit('bombExplodedComplete');
    }

    updateApiPlayerScore(scoreIncrement) {
        const deviceUUID = this.scene.registry.get('deviceUUID');

        fetch(process.env.API_URL + `/update-score.php`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                deviceUUID,
                score: scoreIncrement
            })
        })
            .then(response => response.json())
            .then(data => {
                if (!data.success) {
                    console.error('Failed to update score:', data.message);
                }
            })
            .catch(error => {
                console.error('Error updating score:', error);
            });
    }

}
