// src/objects/Character.js
import Phaser from 'phaser';

export default class Character {
    constructor(scene, x, y, skin) {
        this.scene = scene;
        this.skin = skin;

        this.defaultSize = { width: 20, height: 50 };
        this.defaultDeadSize = { width: 40, height: 40 };

        // Load the sprite with the given skin
        this.sprite = scene.physics.add.sprite(x, y, this.skin).play(`${this.skin}_idle`);
        this.sprite.setCollideWorldBounds(true);
        this.sprite.body.setSize(this.defaultSize.width, this.defaultSize.height);
        //this.sprite.body.setOffset(50, 50);

        this.health = 3; // Players can get hit twice before dying
        this.isDead = false;

        this.usernameText = this.scene.add.text(x, y - 30, '', {
            fontSize: '16px',
            fill: '#fff'
        }).setOrigin(0.5);
    }

    update(movementInfo) {
        this.usernameText.setPosition(this.sprite.x, this.sprite.y - 40);
    }

    takeDamage() {
        if (!this.isDead) {
            this.scene.sound.play('pirateHurt');
            this.health -= 1;

            if (this.health > 1) {
                this.sprite.anims.play(this.skin + '_hit', true); // Play hit animation
            } else if (this.health <= 0) {
                this.die();
            }
        }
    }

    die() {
        this.isDead = true;
        this.sprite.anims.play(this.skin + '_dead-hit', true);
        //this.sprite.body.setSize(this.defaultDeadSize.width, this.defaultDeadSize.height);

        // Send Player flying
        this.sprite.setVelocityY(-500);
        this.sprite.setVelocityX(this.sprite.flipX ? 500 : -500);

        this.sprite.once('animationcomplete', (animation, frame) => {
            if (animation.key === this.skin + '_dead-hit') {
                this.sprite.anims.play(this.skin + '_dead-ground', true);
            }
        });
    }
}
